<template>
  <div class="modal fade" :class="mainClasses" role="dialog" :id="domId" v-bind="$attrs">
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content modal-white-theme" :class="classes">
        <slot v-if="!hideHeader" name="header">
          <div :class="headerClass">
            <h5 v-if="title" class="sub-head mx-auto">{{title}}</h5>
            <button v-if="allowClose" type="button" class="close blue-text" data-dismiss="modal" aria-label="Close">
              <i class="fal fa-times fa-1x"></i>
            </button>
          </div>
        </slot>
        <div :class="bodyClass">
          <slot name="content"></slot>
        </div>
        <div v-if="!hideFooter" class="modal-footer mx-auto">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import $ from 'jquery'
import 'bootstrap'
export default {
  inheritAttrs: false,
  props: {
    mainClasses: {
      type: String,
      default: ''
    },
    bodyClass: {
      type: String,
      default: 'modal-body pt-0'
    },
    classes: {
      type: String
    },
    backdrop: {
      type: String | Boolean,
      default: true
    },
    keyboard: {
      type: Boolean,
      default: true
    },
    domId: {
      type: String,
      required: true
    },
    modalSize: {
      type: String,
      default: 'modal-lg'
    },
    headerClass: {
      type: String,
      default: 'modal-header'
    },
    show: {
      type: Boolean,
      default: false
    },
    allowClose: {
      type: Boolean,
      default: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    hideFooter: {
      type: Boolean,
      default: false
    },
    sizeClass: {
      type: String,
      default: 'modal-lg'
    },
    title: {
      type: String
    }
  },
  mounted() {
    $(this.$el).modal({
      backdrop: this.backdrop,
      keyboard: this.keyboard,
      show: this.show
    })
    $(document).on('show.bs.modal', '.modal', function(event) {
      var zIndex = 1040 + (10 * $('.modal:visible').length);
      $(this).css('z-index', zIndex);
      setTimeout(function() {
        $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
      }, 0);
    });
    $(this.$el).on('hide.bs.modal', (e) => {
      this.$emit('close')
    })
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue) {
        $(this.$el).modal('show')
      } else {
        $(this.$el).modal('hide')
      }
    }
  },
  beforeDestroy(){
    $(this.$el).modal('dispose')
  }
}
</script>
